
@media (min-width : 768px) {
  .marge {
    height: 861px;
  }
  
}
@media (min-width : 850px) {
  .marge {
    height: 750px;
  }
  
}
@media (min-width : 1260px) {
  .marge {
    height: 500px;
  }
  
}