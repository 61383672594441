.Navbar {
  box-shadow: 0px 16px 18px 0px rgba(0,0,0,0.10);
  height: 95px;
  z-index: 999;
}
.button {
  background-color: #f9f9f9;
  color: #4e3a85;
  padding: 15px 35px;
  border-color: #4e3a85 !important;
  transition: background-color 0.3s, color 1s;
  cursor: pointer;
  margin-top: 8px;
  margin-right: 26px;
}
.button:hover {
  background-color: #4e3a85;
  color: #fff;
}
.logo {
  width: 173px;
  margin-top: 20px;
  margin-left: 160px;
}
.logo-switch {
  height: 52px;
  margin-top: 12px;
  margin-right: 141px;
}
@media (max-width: 992px) {
  .button {
    font-size: large;
    margin-top: 8px;
    margin-right: 0;
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
  }
  .logo {
    display: none;
  }
  .logo-switch {
    display: none;
  }
}