.fontSizeLink{
    font-size: 12px;
}

#fontSizeCopyRight{
    font-size: 10px;
}
footer .icone {
    height: 64px;
}
