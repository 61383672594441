.titre {
  font-size: 65px;
}
.button-souscrire {
  background-color: #f9f9f9;
  color: #000000;
  padding: 15px 35px;
  border-color:#5c317a !important;
  transition: background-color 0.3s;
  cursor: pointer;
  width: 340px;
}
.button-souscrire:hover {
  background-color: #2ae6b8;
  color: #5c317a;
}

.button-contacte {
  background-color: #ffff00;
  color: #5c317a;
  padding: 21px 35px;
  border-color: #ffff00 !important;
  transition: background-color 0.3s, color 0.3s;
  transition: border 0.3s, color 0.3s;
  cursor: pointer;
  margin-right: 26px;
  width: 340px;
}
.button-contacte:hover {
  background-color: #ffffff;
  color: #5c317a;
  border: 2px solid #5c317a !important;
}

@media (max-width: 1270px) {
  .boutonD {
    flex-direction: column;
    text-align: center;
  }
  .boutonD a:last-child button {
    margin-top: 10px;
  }
}
