
.imgPosition {
  bottom: -15px;
}

@media (min-width: 768px) {
  
  .imgSize {
    height: min-content;
    margin: auto;
  }
  
}
@media (min-width: 1260px) {
  
  .imgSize {
    height: auto;
    margin: 0;
  }
  
}